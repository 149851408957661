import React, { Component } from 'react'
import Recorder from 'react-recorder-mp3'

import blobToBuffer from 'blob-to-buffer'

export default class App extends Component {
  state = {
    url: '',
    isRecording: false,
    isBlocked: false
  }

  recorderType = "react-recorder-mp3"
  
  onStatusChange = (isRecording) => {
    console.log("TCL: App -> onStatusChange -> isRecording", isRecording)
    this.setState({ isRecording })
  }

  togglePlay = () => {
    this.setState({ isRecording: !this.state.isRecording })
    console.log("TCL: App -> togglePlay -> this.state.isRecording", this.state.isRecording)
  }

  start = () => {
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      this.setState({ isRecording: true })
    }
  };

  stop = () => {
    this.setState({ isRecording: false })
  };

  _renderContent = () => {
    const Ui = this.props.Ui
    return (
      <Ui downloadFilename={`${this.recorderType}.mp3`} onStart={this.start} onStop={this.stop} isRecording={this.state.isRecording} url={this.state.url} title={this.recorderType} />
    )
  }

  render() {
    const {
      url
    } = this.state

    return (
      <Recorder
        onRecordingComplete={this._onRecordingComplete}
        onRecordingError={this._onRecordingError}
        onStatusChange={this._onStatusChange}
        renderContent={this._renderContent}
        isRecording={this.state.isRecording}
      />
    )
  }

  _onRecordingComplete = (blob) => {
    blobToBuffer(blob, (err, buffer) => {
      if (err) {
        console.error(err)
        return
      }

      console.log('recording', blob)

      if (this.state.url) {
        window.URL.revokeObjectURL(this.state.url)
      }

      this.setState({
        url: window.URL.createObjectURL(blob)
      })
    })
  }

  _onRecordingError = (err) => {
    console.log('error recording', err)

    if (this.state.url) {
      window.URL.revokeObjectURL(this.state.url)
    }

    this.setState({ url: null })
  }
}
