import React, { Component } from 'react'
import { ReactMicGold } from 'react-mic-gold'

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadLinkURL: null,
      blobURL: null,
      isRecording: false
    }
  }

  recorderType = 'react-mic-gold'

  onData = (recordedBlob) => {
    console.log('onData called', recordedBlob)
  }

  onSave = (blobObject) => {
    console.log(`onSave downloadLinkURL=${blobObject.blobURL}`)
    this.setState({ downloadLinkURL: blobObject.blobURL })
  }

  onStop = (blobObject) => {
    console.log(`onStop blobURL=${blobObject.blobURL}`)
    this.setState({ blobURL: blobObject.blobURL })
  }

  startRecording = () => {
    this.setState({ isRecording: true })
  }

  stopRecording = () => {
    this.setState({ isRecording: false })
  }

  render() {
    const {
      blobURL,
      downloadLinkURL,
      isRecording
    } = this.state
    const Ui = this.props.Ui


    // detect and set sample rate. It seems that react-mic-gold does not
    // properly configure sample rate, so we must detect the default and provide
    // actual microphone sample rate to react-mic-gold prop

    
    const rmgProps = () => ({
      mimeType: 'audio/mp3',
      sampleRate: (new (window.AudioContext || window.webkitAudioContext)()).sampleRate,
      channelCount: 1,
      record: isRecording,
      onStop: this.onStop,
      onSave: this.onSave,
      //onData: this.onData,
      width: 300
      })
  
    //console.log("rmg props:", rmgProps())
    return (
      <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
        <ReactMicGold  {...rmgProps()} />
        <div>
          <Ui downloadFilename={`${this.recorderType}.mp3`} onStart={this.startRecording} onStop={this.stopRecording} isRecording={isRecording} url={blobURL} title={this.recorderType}  demos={["https://voice-record.firebaseapp.com", "https://hackingbeauty.github.io/react-mic-gold/"]} />

        </div>
      </div>
    );
  }
}

/*
    // detect and set sample rate. It seems that react-mic-gold does not
    // properly configure sample rate, so we must detect and provide
    // actual sample rate to react-mic-gold prop

    
        <ReactMicGold
          record={boolean}         // defaults -> false.  Set to true to begin recording
          visualSetting="sinewave" // defaults -> "sinewave".  Other option is "frequencyBars"
          className={string}       // provide css class name
          onStop={function}        // required - called when audio stops recording
          onData={function}        // optional - called when chunk of audio data is available
          strokeColor={string}     // sinewave or frequency bar color
          backgroundColor={string} // background color
          echoCancellation={boolean} // defaults -> false
          autoGainControl={boolean}  // defaults -> false
          noiseSuppression={boolean} // defaults -> false
          channelCount={number}     // defaults -> 2 (stereo).  Specify 1 for mono.

          //ReactMicGold only: 
          sampleRate={96000}        // defaults -> 44100 (44.1 kHz).  It accepts values only in range: 22050 to 96000 
          timeSlice={3000}          // defaults -> 4000 milliseconds.  The interval at which captured audio is returned to onData callback 
          pause={boolean}          // defaults -> false
          onBlock={function}       // optional - called if user selected "block" when prompted to allow microphone access
          bitRate={256000}          // defaults -> 128000 (128kbps). 
          mimeType="audio/webm"     // defaults -> "audio/webm".  Set to "audio/wav" for WAV or "audio/mp3" for MP3 audio format 
          onSave                    // ????
          height={200}
          width= {300}
          onStart={this.allowMicrophoneAccess}
        />
*/

