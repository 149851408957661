import React, { useState } from 'react';
import PropTypes from 'prop-types'
import RecorderUI from './RecorderUI'

/**
 * Select and render a component
 */


const RecorderSelector = ({ recorders, repositories }) => {
  const recorderEntries = Object.entries(recorders)
  const [selectedOption, setSelectedOption] = useState(recorderEntries[0][0])


  const handleRadioChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value)
  };

  const Recorder = recorders[selectedOption]

  return (
    <div>
      <h3>Select a Recorder</h3>
      <form>
        {recorderEntries.map(([key, val]) => (
          <div key={key} style={{marginBottom: 10}}>
            <input type="radio" id={key} name="recorder" value={key} onChange={handleRadioChange} checked={selectedOption === key} />
            <label htmlFor={key}> -- {key} <a href={repositories[key]} target="_blank" >(REPO)</a>  </label>
            <br />
          </div>
        ))}
      </form>
      <hr />
      <Recorder Ui={RecorderUI} />
    </div>
  );
};

RecorderSelector.propTypes = {
  recorders: PropTypes.objectOf(PropTypes.func).isRequired
  //expecting key, value pairs where keys are recorder titles
  //and values are renderable recorder components
}

export default RecorderSelector;
