import React from 'react';
import ReactRecorderMp3 from './react-recorder-mp3/example/src/App'
import ReactMp3AudioRecording from './react-mp3-audio-recording/src/App'
import ReactMicGold from './react-mic-gold/demo'
import VmsgRecorder from './simple-vmsg-react-demo'
import RecorderSelector from './RecorderSelector'


/**
 * Demonstrate various available MP3 recorders
 */
function App() {

  const recorders = {
    'react-recorder-mp3': ReactRecorderMp3, 
    'react-mp3-audio-recording': ReactMp3AudioRecording,
    'react-mic-gold (proprietery)': ReactMicGold,
    'vmsg': VmsgRecorder 
  }

  const repositories = {
    'react-recorder-mp3':  'https://github.com/shakogegia/react-recorder-mp3',                
    'react-mp3-audio-recording': 'https://github.com/Matheswaaran/react-mp3-audio-recording',    
    'react-mic-gold (proprietery)': 'https://react-mic-gold.professionalreactapp.com/sales-page34701298',                          
    'vmsg': 'https://github.com/Kagami/vmsg'
  }                               


  return (
    <div>
      <h1>MP3 Recorders Demonstration!</h1>
      <RecorderSelector recorders = {recorders}  repositories = { repositories } />
    </div>
  );
}

export default App;
