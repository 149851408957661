import React from "react";
import { render } from "react-dom";
import vmsg from "./vmsg/vmsg";

/**
 * @@ must check on resource cleanup (this.state.url)
 * 
 */

const recorder = new vmsg.Recorder({
  wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm"
});

class App extends React.Component {
  state = {
    isLoading: false,
    isRecording: false,
    url: null
  };
  recorderType = 'simple-vmsg-react-demo'

  record = async () => {
    this.setState({ isLoading: true });

    if (this.state.isRecording) {
      const blob = await recorder.stopRecording();
      this.setState({
        isLoading: false,
        isRecording: false,
        url: URL.createObjectURL(blob)
      });
    } else {
      try {
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();
        this.setState({ isLoading: false, isRecording: true });
      } catch (e) {
        console.error(e);
        this.setState({ isLoading: false });
      }
    }
  };
  render() {
    const { isLoading, isRecording, url } = this.state;
    const Ui= this.props.Ui

    return (
        <Ui  disabled={ isLoading } downloadFilename={`${this.recorderType}.mp3`} onStart={this.record} onStop={this.record} isRecording={isRecording} url={ url } title={this.recorderType}   demos={["https://v67oz43lm7.csb.app/"]} />
    );
  }
}

export default App

