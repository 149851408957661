import React from 'react';
import PropTypes from 'prop-types'


const RecorderUI = ({disabled, downloadFilename, isRecording, url, onStart, onStop, title, demos}) => {
    return (
        <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div>
                <button onClick={onStart} style={{margin: 10}} disabled={disabled || isRecording}>Record</button>
                <button onClick={onStop}  style={{margin: 10}} disabled={disabled || !isRecording}>Stop</button>
                <a href={url} download=  {downloadFilename}>
                   <button style={{margin: 10}} disabled={disabled || isRecording || !url} >Download</button>
                </a>
            </div>
            <div>
                <audio src={isRecording ? "null" : url} controls="controls" controlsList="nodownload"/>
            </div>
            <div><h4>{title}</h4></div>
            <div>
                { demos && <ul> 
                    {demos.map( (demo) => (
                        <li key={demo}><a href={demo} target="_blank">External Demo </a></li>
                    ))}
                </ul>}
            </div>
        </div>
    )
}

RecorderUI.propTypes = {
    disabled: PropTypes.bool,
    downloadFilename: PropTypes.string,
    isRecording: PropTypes.bool,
    url: PropTypes.string,
    onStart: PropTypes.func,
    onStop: PropTypes.func,
    title: PropTypes.string
}

RecorderUI.defaultProps = {
    isRecording: false,
    url: '',
    onStart: ()=>{console.log("start")},
    onStop:  ()=>{console.log("stop")},
    title: ''
}

export default RecorderUI
